<template>
  <div class="patient__History">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="col-9 p-0 align-self-center font-weight-bold d-flex align-items-center"
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.invoice_insurance") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="col-7 align-items-center align-self-center mt-1 pl-0 m-2 pr-0 m-2 m-d-flex w-reset m-sm-1"
        >
          <span class="align-items-center align-self-center pr-2"
            ><el-tag
              >{{ $t("message.invoices") }}:
              {{ count.total | formatMoney }}
            </el-tag></span
          >
          <span class="align-items-center align-self-center pr-2"
            ><el-tag type="success"
              >{{ $t("message.paid_invoices") }} :
              {{ count.paid | formatMoney }}
            </el-tag></span
          >
          <span class="align-items-center align-self-center pr-2"
            ><el-tag type="danger"
              >{{ $t("message.not_paid_invoices") }}:
              {{ count.not_paid | formatMoney }}
            </el-tag></span
          >
        </div>
        <div
          class="col-3 align-items-center align-self-center text-right pr-0 d-flex justify-content-end"
        >
          <!-- <el-button
            v-can="'specialties.create'"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
          >
            {{ $t("message.create") }}
          </el-button> -->
        </div>
      </div>

      <table class="table table-bordered table-hover" v-loading="loadingData">
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <crm-th
              :column="columns.id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.insurance_id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.count"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.total_amount"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.is_paid"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.created_at"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.settings"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              >
              </el-input>
            </th>
            <th v-if="columns.insurance_id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.insurance_id"
                :placeholder="columns.insurance_id.title"
              >
              </el-input>
            </th>
            <th v-if="columns.count.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.count"
                :placeholder="columns.count.title"
              >
              </el-input>
            </th>

            <th v-if="columns.total_amount.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.total_amount"
                :placeholder="columns.total_amount.title"
              >
              </el-input>
            </th>
            <th v-if="columns.is_paid.show">
              <el-select
                filterable
                clearable
                :placeholder="columns.is_paid.title"
                size="mini"
                v-model="filterForm.is_paid"
              >
                <el-option
                  key="true"
                  :label="$t('message.paid')"
                  :value="true"
                ></el-option>
                <el-option
                  key="false"
                  :label="$t('message.not_paid')"
                  :value="false"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                v-model="filterForm.created_at"
                :placeholder="columns.created_at.title"
                size="mini"
              >
              </el-date-picker>
            </th>

            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="invoice in list"
            :key="invoice.id"
            class="cursor-pointer"
            :style="colorPicker(invoice)"
          >
            <td v-if="columns.id.show">{{ invoice.id }}</td>
            <td v-if="columns.insurance_id.show">
              {{ invoice.insurance.name }}
            </td>
            <td v-if="columns.count.show">{{ invoice.count }}</td>
            <td v-if="columns.total_amount.show">{{ invoice.total_amount }}</td>
            <td v-if="columns.is_paid.show">
              {{
                invoice.is_paid ? $t("message.paid") : $t("message.not_paid")
              }}
            </td>
            <td v-if="columns.created_at.show">{{ invoice.created_at }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <el-button
                round
                @click="show(invoice)"
                size="mini"
                type="primary"
                icon="el-icon-view"
                >{{ $t("message.show_0") }}</el-button
              >
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="80%"
      :wrapperClosable="false"
      :visible.sync="drawer.show.status"
      :ref="drawer.show.name"
      @opened="drawerOpened(drawer.show.component)"
      @closed="drawerClosed(drawer.show.component)"
    >
      <crm-show
        :selected="selectedModel"
        :ref="drawer.show.component"
        :drawer-name="drawer.show.name"
      >
      </crm-show>
    </el-drawer>
    <!-- end modal -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CrmShow from "./components/crm-show";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [list, drawer],
  components: {
    CrmShow,
  },
  data() {
    return {
      loadingData: false,
      selectedModel: {},
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        show: {
          name: "show",
          status: false,
          component: "componentDrawerShow",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "invoiceInsurance/list",
      count: "invoiceInsurance/count",
      columns: "invoiceInsurance/columns",
      pagination: "invoiceInsurance/pagination",
      filter: "invoiceInsurance/filter",
      sort: "invoiceInsurance/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "invoiceInsurance/index",
      updateSort: "invoiceInsurance/updateSort",
      updateFilter: "invoiceInsurance/updateFilter",
      updateColumn: "invoiceInsurance/updateColumn",
      updatePagination: "invoiceInsurance/updatePagination",
      editModel: "invoiceInsurance/show",
      empty: "invoiceInsurance/empty",
      delete: "invoiceInsurance/destroy",
      refreshData: "invoiceInsurance/refreshData",
    }),
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    colorPicker(form) {
      if (form.is_paid) {
        return "background-color: rgb(171 255 168 / 62%)";
      } else {
        return "background-color: #ffffff";
      }
    },

    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    edit(model) {
      this.selectedModel = model;
      this.drawer.update.status = true;
    },
    async show(model) {
      await this.editModel(model.id)
        .then((res) => {
          this.drawer.show.status = true;
        })
        .catch((err) => {});
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>